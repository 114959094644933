<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        type="selection"
        width="55"
      ></el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.mandant.label"
        :prop="fields.mandant.name"
        width="100"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.mandant.label"
            :readPermission="fields.mandant.readPermission"
            :navigatePermission="false"
            :value="presenter(scope.row, 'mandant')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.mitarbeiterNr.label"
        :prop="fields.mitarbeiterNr.name"
        width="80"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'mitarbeiterNr') }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.vollname.label"
        :prop="fields.vollname.name"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'vollname') }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.titel.label"
        :prop="fields.titel.name"
        width="80"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'titel') }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.beruf.label"
        :prop="fields.beruf.name"
        width="120"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'beruf') }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.funktion.label"
        :prop="fields.funktion.name"
        width="120"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'funktion') }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        v-for="col in predictingMonths"
        :key="col.prop"
        :prop="col.prop"
        :label="col.prop"
      >
        <template v-slot="scope">
          <strong>
            {{ presenterRow(scope.row, col.prop) }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.totalBrutto.label"
        :prop="fields.totalBrutto.name"
        width="100"
        align="right"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'totalBrutto') }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.totalGesamtkosten.label"
        :prop="fields.totalGesamtkosten.name"
        width="100"
        align="right"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'totalGesamtkosten') }}
          </strong>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { AuswertungModel } from '@/modules/auswertung/auswertung-model';
import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';

const { fields } = AuswertungModel;

export default {
  name: 'app-auswertung-list-table',

  data() {
    return {
      columns: [
        {
          prop: 'date',
          label: 'Date',
          minWidth: '100px',
        },
        {
          prop: 'name',
          label: 'Name',
          minWidth: '150px',
        },
        {
          prop: 'address',
          label: 'Address',
        },
      ],
    };
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.table.selection;
      },
      (rows) => {
        this.$store.dispatch('auswertung/list/doSetSelectedRows', rows);
      },
    );
  },

  computed: {
    ...mapGetters({
      rows: 'auswertung/list/rows',
      predictingMonths: 'auswertung/list/predictingMonths',
      count: 'auswertung/list/count',
      loading: 'auswertung/list/loading',
      pagination: 'auswertung/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),

    columnsArr() {
      if (!this.rows || this.rows.length === 0) {
        return [];
      }

      const auswertungData = this.rows[0].auswertungData;
      const mon = [];
      for (const item of auswertungData) {
        mon.push({
          prop: item.month,
          label: item.month,
        });
      }

      return mon;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'auswertung/list/doChangeSort',
      doChangePaginationCurrentPage:
        'auswertung/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'auswertung/list/doChangePaginationPageSize',
      doMountTable: 'auswertung/list/doMountTable',
    }),

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total Gesamtkosten';
          return;
        }
        if (column.property === 'mitarbeiterNr') {
          sums[index] = '';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          const sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Vue.Formatter.formatCurrency(sum);
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },

    presenterRow(row, col) {
      let str = '';
      const item = row.auswertungData.filter((f) => f.month === col);
      if (item && item.length > 0) {
        if (item[0].kst) {
          item[0].kst.forEach((f) => {
            str = str.concat(
              '\n',
              `${f.kostenstelle}: ${Vue.Formatter.formatCurrency(f.brutto)}`,
            );
          });
        } else {
          str = item[0].status;
        }
      }

      return str;
    },

    presenter(row, fieldName) {
      return AuswertungModel.presenter(row, fieldName);
    },

    presenterGrundgehalt(rows, fieldName) {
      const tmp = {};

      const filter = rows.filter((f) => f[`${fieldName}`] !== null);
      tmp[`${fieldName}`] = filter.map((f) => f[`${fieldName}`]);

      return AuswertungModel.presenter(tmp, fieldName);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
