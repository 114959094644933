import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DecimalField from '@/shared/fields/decimal-field';
import { KostenstelleField } from '@/modules/kostenstelle/kostenstelle-field';
import { VerwendungKostenstelleField } from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-field';
import { GenericModel } from '@/shared/model/generic-model';
import { StatistikAustriaField } from '@/modules/statistik-austria/statistik-austria-field';
import { MandantField } from '@/modules/mandant/mandant-field';
import MoneyField from '@/shared/fields/money-field';
import { FakultaetField } from '@/modules/fakultaet/fakultaet-field';
import DateRangeField from '@/shared/fields/date-range-field';

function label(name) {
  return i18n(`entities.auswertung.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.auswertung.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  mitarbeiterNr: new StringField('mitarbeiterNr', label('mitarbeiterNr'), {
    required: false,
    max: 50,
  }),

  vollname: new StringField('vollname', label('vollname')),
  titel: new StringField('titel', label('titel')),
  beruf: new StringField('beruf', label('beruf')),
  funktion: new StringField('funktion', label('funktion')),
  svNummer: new StringField('svNummer', label('svNummer'), {
    required: true,
    matches: /^[0-9]{4}/,
    max: 20,
  }),

  grundgehaltGesamtbrutto: new MoneyField(
    'grundgehaltGesamtbrutto',
    label('grundgehaltGesamtbrutto'),
    {
      scale: 2,
      min: 0,
      max: 10000000,
      required: false,
    },
  ),
  gesamtbrutto: new MoneyField('gesamtbrutto', label('gesamtbruttoF'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  gesamtwochenstunden: new DecimalField(
    'gesamtwochenstunden',
    label('gesamtwochenstunden'),
    {
      scale: 2,
      min: 0,
      max: 1000,
      required: false,
    },
  ),

  verwendung: StatistikAustriaField.relationToOne(
    'verwendung',
    label('verwendung'),
    {},
  ),

  wochenstunden: new DecimalField('wochenstunden', label('wochenstunden'), {
    scale: 2,
    min: 0,
    max: 1000,
    required: false,
  }),
  brutto: new DecimalField('brutto', label('brutto'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  mandant: MandantField.relationToOne('mandant', label('mandant'), {}),
  mandantFilter: MandantField.relationToMany('mandant', label('mandant'), {}),
  kostenstelle: KostenstelleField.relationToMany(
    'kostenstelle',
    label('kostenstelle'),
    {},
  ),
  fakultaet: FakultaetField.relationToMany('fakultaet', label('fakultaet'), {}),
  verwendungKostenstelle: VerwendungKostenstelleField.relationToMany(
    'verwendungKostenstelle',
    label('verwendungKostenstelle'),
    {},
  ),

  totalGesamtkosten: new DecimalField(
    'totalGesamtkosten',
    label('totalGesamtkosten'),
    {
      scale: 2,
      min: 0,
      max: 10000000,
      required: false,
    },
  ),
  totalBrutto: new DecimalField('totalBrutto', label('totalBrutto'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),

  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'active',
        label: enumeratorLabel('status', 'active'),
      },
      {
        id: 'inactive',
        label: enumeratorLabel('status', 'inactive'),
      },
      {
        id: 'ruhezeit',
        label: enumeratorLabel('status', 'ruhezeit'),
      },
      {
        id: 'mutterschutz',
        label: enumeratorLabel('status', 'mutterschutz'),
      },
      {
        id: 'mutterkarenz',
        label: enumeratorLabel('status', 'mutterkarenz'),
      },
      {
        id: 'bildungskarenz',
        label: enumeratorLabel('status', 'bildungskarenz'),
      },
    ],
    {},
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  updatedAtRange: new DateTimeRangeField(
    'updatedAtRange',
    label('updatedAtRange'),
  ),
  auswertungForRange: new DateRangeField(
    'auswertungForRange',
    label('auswertungForRange'),
    { required: true },
  ),
};

export class AuswertungModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
